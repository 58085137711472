<template>
  <div class="searchTerms">
    <form action="/">
      <van-search
          v-model="value"
          show-action
          @input="oninput"
          shape='round'
          clear-trigger="none"
          :placeholder="$t('placeholder')"
          :autofocus="true"
          :error="isError"
          @search="tosearch"
      >
        <template #action>
          <div v-if="isok" @click="toHome()">{{$t('cancel')}}</div>
          <div v-if="!isok" @click="tosearch()">{{$t('search')}}</div>
        </template>

      </van-search>
    </form>
    <router-view :parent-data="value"/>
    <layout></layout>
  </div>
</template>
<script>
import {getSuggest} from '@/api/searchs/index';
import {mapGetters} from 'vuex'
import layout from '@/layout/index'
import {Toast} from "vant";

export default {
  name: '',
  components: {
    layout
  },
  data() {
    return {
      value: '',
      isok: true,
      isError: false
    }
  },
  // 计算属性
  computed: {
    ...mapGetters([
      'getvalue'
    ])
  },
  watch: {},
  mounted() {
    this.getLiShi()
    this.getValue()
  },
  methods: {
    //回到shouye
    toHome() {
      this.$router.push(localStorage.getItem('path'))
    },
    //点击搜索
    tosearch() {
      if (this.value === '') {
        this.isError = true
        Toast({
          message: this.$t('searchEmptyTip'),
          position: 'bottom',
        });
      } else {
        this.$router.push({
          path: '/search/recommendList',
          query: {
            'kw': this.value
          }
        })
        this.isok = true
        const info = this.value
        let existingInfo = localStorage.getItem('search')
        if (existingInfo) {
          let history = existingInfo.split(',')
          // 判断是否已经在历史记录中，如果是则添加到头部
          if (history.includes(info)) {
            history = history.filter(element => element !== info);
          }

          history.unshift(info)
          // 只保留20条数据
          if (history.length > 20) {
            history.splice(20);
          }

          existingInfo = history.join(',')
        } else {
          existingInfo = info;
        }
        localStorage.setItem('search', existingInfo)
      }
    },
    // 当input中内容发生变化时
    oninput(value) {
      //
      if (value != '') {
        const list = {
          kw: value,
          pn: 1
        }
        getSuggest(list).then(res => {
          //
          const amount = res.result
          this.$store.dispatch('getSearchInfo', amount)
        })
        this.isok = false
        //
        if (this.$route.name == 'HistoryRecommend') {
          this.$router.push({
            path: '/search/searchRecommend',
            query: {kw: this.value}
          })
        } else {
          this.$router.replace({
            path: '/search/searchRecommend',
            query: {kw: this.value}
          })
        }
      } else {
        this.$router.push('/search/historyRecommend')
        this.isok = true
      }
    },
    // 获取点击历史的数据
    getLiShi() {
      this.value = Object.values(this.$route.query).join('')
    },
    // 获取点击建议得value值
    getValue() {
      this.$store.watch(
          (state) => state.searchValue,
          (newVal) => {
            this.value = newVal;
          }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-search {
  background: none;
  padding: 10PX 0;
  margin: 0 0 0 10px;
}

.searchTerms {
  width: 100vw;
  padding: 0 0px;
  margin: 0;
}

/deep/ .van-search__content {
  background-color: var(--minor-color);
  width: 70vw;
}

/deep/ .van-field__control {
  color: var(--title-color);
}
/deep/.van-search__action{
  color: var(--title-color);
}
/deep/.van-search__action:active{
  background-color: var(--main-color);
}
</style>
